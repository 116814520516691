import React, {useMemo, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {FlexColumnStartStart, FlexRowCenterBetween} from '../../widget';
import {Color, INPUT_TYPES} from '../../constants';
import {COUNTIES, TOWNS_OF_COUNTY} from '../../taiwan';
import {useForm} from 'react-hook-form';
import {Input} from '../../Components/Input';
import {RectButton} from '../../Components/RectButton';

function UserProfilePage(props) {
  const [user] = useOutlet('user');
  const profileForm = useForm();
  const [townOptions, setTownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [actions] = useOutlet('actions');

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.city) {
      const value = user.city;
      setTownOptions(
        TOWNS_OF_COUNTY[value]
          ? TOWNS_OF_COUNTY[value]?.map((t) => ({
              label: t.townname,
              value: t.townname,
            }))
          : [],
      );
    }
  }, [user]);

  const FIELDS = useMemo(() => {
    if (!user || townOptions.length <= 0) {
      return [];
    }
    return [
      {
        placeholder: '請輸入會員識別',
        name: 'id',
        defaultValue: user.id,
        rules: {
          required: true,
        },
        message: {
          required: '會員識別不可為空',
        },
        disabled: true,
        label: '會員識別',
        type: INPUT_TYPES.text,
      },
      {
        placeholder: '請輸入會員姓氏',
        name: 'firstName',
        defaultValue: user.first_name,
        rules: {
          required: true,
        },
        message: {
          required: '會員姓氏不可為空',
        },
        label: '會員姓氏',
        type: INPUT_TYPES.text,
        disabled: !isEditing,
      },
      {
        placeholder: '請輸入會員姓名',
        name: 'lastName',
        defaultValue: user.last_name,
        rules: {
          required: true,
        },
        message: {
          required: '會員姓名不可為空',
        },
        label: '會員姓名',
        type: INPUT_TYPES.text,
        disabled: !isEditing,
      },
      {
        placeholder: '請輸入會員手機',
        name: 'phone',
        defaultValue: user.phone,
        rules: {
          required: true,
          pattern: /^09[0-9]{8}$/,
        },
        message: {
          required: '會員手機不可為空',
          pattern: '手機格式錯誤',
        },
        label: '會員手機',
        type: INPUT_TYPES.text,
        hint: '為後續登入使用之帳號',
        disabled: true,
      },
      {
        placeholder: '請輸入會員信箱',
        name: 'email',
        defaultValue: user.email,
        rules: {
          required: true,
          pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+(([.-])[A-Za-z0-9]+)*\.[A-Za-z]+$/,
        },
        disabled: true,
        message: {
          required: '會員信箱不可為空',
          pattern: '信箱格式不正確',
        },
        label: '會員信箱',
        type: INPUT_TYPES.text,
        hint: '避免使用PChome信箱以免收不到認證信',
      },
      {
        placeholder: '請輸入會員所屬縣市',
        name: 'county',
        defaultValue: user.city,
        rules: {
          required: true,
        },
        message: {
          required: '會員縣市不可為空',
        },
        label: '會員縣市',
        type: INPUT_TYPES.select,
        disabled: !isEditing,
        options: COUNTIES.map((c) => ({
          label: c.county_name,
          value: c.county_name,
        })),
        handleChange: (e) => {
          const {value} = e.target;
          profileForm.setValue('county', value);
          profileForm.setValue('town', '');
          setTownOptions(
            TOWNS_OF_COUNTY[value]
              ? TOWNS_OF_COUNTY[value]?.map((t) => ({
                  label: t.townname,
                  value: t.townname,
                }))
              : [],
          );
        },
      },
      {
        placeholder: '請輸入會員所屬鄉鎮',
        name: 'town',
        defaultValue: user.district,
        rules: {
          required: true,
        },
        message: {
          required: '會員鄉鎮不可為空',
        },
        label: '會員鄉鎮',
        type: INPUT_TYPES.select,
        options: townOptions,
        disabled: townOptions.length <= 0 || !isEditing,
      },
    ];
  }, [isEditing, profileForm, townOptions, user]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        UserSessionId: user.userSessionId,
        LoyaltyMember: {
          MemberId: user.id,
          UserName: `user-${data.phone}`,
          Password: '',
          Email: data.email,
          FirstName: data.firstName,
          LastName: data.lastName,
          DateOfBirth: '',
          MobilePhone: '',
          HomePhone: '',
          Gender: '',
          Address1: '',
          Suburb: `${data.county}-${data.town}`,
          City: '',
          ClubID: '1',
        },
      };
      await actions.profileUpdate(payload);
      await actions.fetchMe();
      setIsEditing(false);
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <div className="view">
        {FIELDS.slice(0, 1).map((f) => (
          <Input
            style={{marginBottom: 10}}
            key={f.name}
            field={f}
            register={profileForm.register(f.name, f.rules)}
            errors={profileForm.formState.errors}
          />
        ))}
        <div className="row">
          {FIELDS.slice(1, 3).map((f) => (
            <Input
              style={{marginBottom: 10, width: '45%'}}
              key={f.name}
              field={f}
              register={profileForm.register(f.name, f.rules)}
              errors={profileForm.formState.errors}
            />
          ))}
        </div>
        {FIELDS.slice(3, 5).map((f) => (
          <Input
            style={{marginBottom: 10}}
            key={f.name}
            field={f}
            register={profileForm.register(f.name, f.rules)}
            errors={profileForm.formState.errors}
          />
        ))}

        <div className="row">
          {FIELDS.slice(5, FIELDS.length).map((f) => (
            <Input
              style={{marginBottom: 10, width: '45%'}}
              key={f.name}
              field={f}
              register={profileForm.register(f.name, f.rules)}
              errors={profileForm.formState.errors}
            />
          ))}
        </div>

        <div style={{flex: 1}} />

        {!isEditing && (
          <RectButton
            isLoading={false}
            tintColor={Color.white}
            style={{
              backgroundColor: Color.orange,
              marginBottom: 10,
              width: '100%',
            }}
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}>
            編輯資料
          </RectButton>
        )}

        {isEditing && (
          <RectButton
            isLoading={isLoading}
            tintColor={Color.white}
            style={{
              backgroundColor: Color.orange,
              marginBottom: 10,
              width: '100%',
            }}
            onClick={profileForm.handleSubmit(onSubmit)}>
            確定更新
          </RectButton>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    ${FlexColumnStartStart};
    & > .row {
      width: 100%;
      ${FlexRowCenterBetween}
    }
  }
`;

export default UserProfilePage;
