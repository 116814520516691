export const COUNTIES = [
  {
    county_name: '臺北市',
    county_code: 'A',
  },
  {
    county_name: '臺中市',
    county_code: 'B',
  },
  {
    county_name: '基隆市',
    county_code: 'C',
  },
  {
    county_name: '臺南市',
    county_code: 'D',
  },
  {
    county_name: '高雄市',
    county_code: 'E',
  },
  {
    county_name: '新北市',
    county_code: 'F',
  },
  {
    county_name: '宜蘭縣',
    county_code: 'G',
  },
  {
    county_name: '桃園市',
    county_code: 'H',
  },
  {
    county_name: '嘉義市',
    county_code: 'I',
  },
  {
    county_name: '新竹縣',
    county_code: 'J',
  },
  {
    county_name: '苗栗縣',
    county_code: 'K',
  },
  {
    county_name: '南投縣',
    county_code: 'M',
  },
  {
    county_name: '彰化縣',
    county_code: 'N',
  },
  {
    county_name: '新竹市',
    county_code: 'O',
  },
  {
    county_name: '雲林縣',
    county_code: 'P',
  },
  {
    county_name: '嘉義縣',
    county_code: 'Q',
  },
  {
    county_name: '屏東縣',
    county_code: 'T',
  },
  {
    county_name: '花蓮縣',
    county_code: 'U',
  },
  {
    county_name: '臺東縣',
    county_code: 'V',
  },
  {
    county_name: '金門縣',
    county_code: 'W',
  },
  {
    county_name: '澎湖縣',
    county_code: 'X',
  },
  {
    county_name: '連江縣',
    county_code: 'Z',
  },
];

export const TOWNS_OF_COUNTY = {
  臺北市: [
    {
      townname: '松山區',
    },
    {
      townname: '大安區',
    },
    {
      townname: '中正區',
    },
    {
      townname: '萬華區',
    },
    {
      townname: '大同區',
    },
    {
      townname: '中山區',
    },
    {
      townname: '文山區',
    },
    {
      townname: '南港區',
    },
    {
      townname: '內湖區',
    },
    {
      townname: '士林區',
    },
    {
      townname: '北投區',
    },
    {
      townname: '信義區',
    },
  ],
  臺中市: [
    {
      townname: '中區',
    },
    {
      townname: '東區',
    },
    {
      townname: '南區',
    },
    {
      townname: '西區',
    },
    {
      townname: '北區',
    },
    {
      townname: '西屯區',
    },
    {
      townname: '南屯區',
    },
    {
      townname: '北屯區',
    },
    {
      townname: '豐原區',
    },
    {
      townname: '東勢區',
    },
    {
      townname: '大甲區',
    },
    {
      townname: '清水區',
    },
    {
      townname: '沙鹿區',
    },
    {
      townname: '梧棲區',
    },
    {
      townname: '后里區',
    },
    {
      townname: '神岡區',
    },
    {
      townname: '潭子區',
    },
    {
      townname: '大雅區',
    },
    {
      townname: '新社區',
    },
    {
      townname: '石岡區',
    },
    {
      townname: '外埔區',
    },
    {
      townname: '大安區',
    },
    {
      townname: '烏日區',
    },
    {
      townname: '大肚區',
    },
    {
      townname: '龍井區',
    },
    {
      townname: '霧峰區',
    },
    {
      townname: '太平區',
    },
    {
      townname: '大里區',
    },
    {
      townname: '和平區',
    },
  ],
  基隆市: [
    {
      townname: '中正區',
    },
    {
      townname: '七堵區',
    },
    {
      townname: '暖暖區',
    },
    {
      townname: '仁愛區',
    },
    {
      townname: '中山區',
    },
    {
      townname: '安樂區',
    },
    {
      townname: '信義區',
    },
  ],
  臺南市: [
    {
      townname: '東區',
    },
    {
      townname: '南區',
    },
    {
      townname: '北區',
    },
    {
      townname: '安南區',
    },
    {
      townname: '安平區',
    },
    {
      townname: '中西區',
    },
    {
      townname: '新營區',
    },
    {
      townname: '鹽水區',
    },
    {
      townname: '柳營區',
    },
    {
      townname: '白河區',
    },
    {
      townname: '後壁區',
    },
    {
      townname: '東山區',
    },
    {
      townname: '麻豆區',
    },
    {
      townname: '下營區',
    },
    {
      townname: '六甲區',
    },
    {
      townname: '官田區',
    },
    {
      townname: '大內區',
    },
    {
      townname: '佳里區',
    },
    {
      townname: '西港區',
    },
    {
      townname: '七股區',
    },
    {
      townname: '將軍區',
    },
    {
      townname: '北門區',
    },
    {
      townname: '學甲區',
    },
    {
      townname: '新化區',
    },
    {
      townname: '善化區',
    },
    {
      townname: '新市區',
    },
    {
      townname: '安定區',
    },
    {
      townname: '山上區',
    },
    {
      townname: '左鎮區',
    },
    {
      townname: '仁德區',
    },
    {
      townname: '歸仁區',
    },
    {
      townname: '關廟區',
    },
    {
      townname: '龍崎區',
    },
    {
      townname: '玉井區',
    },
    {
      townname: '楠西區',
    },
    {
      townname: '南化區',
    },
    {
      townname: '永康區',
    },
  ],
  高雄市: [
    {
      townname: '鹽埕區',
    },
    {
      townname: '鼓山區',
    },
    {
      townname: '左營區',
    },
    {
      townname: '楠梓區',
    },
    {
      townname: '三民區',
    },
    {
      townname: '新興區',
    },
    {
      townname: '前金區',
    },
    {
      townname: '苓雅區',
    },
    {
      townname: '前鎮區',
    },
    {
      townname: '旗津區',
    },
    {
      townname: '小港區',
    },
    {
      townname: '鳳山區',
    },
    {
      townname: '林園區',
    },
    {
      townname: '大寮區',
    },
    {
      townname: '大樹區',
    },
    {
      townname: '大社區',
    },
    {
      townname: '仁武區',
    },
    {
      townname: '鳥松區',
    },
    {
      townname: '岡山區',
    },
    {
      townname: '橋頭區',
    },
    {
      townname: '燕巢區',
    },
    {
      townname: '田寮區',
    },
    {
      townname: '阿蓮區',
    },
    {
      townname: '路竹區',
    },
    {
      townname: '湖內區',
    },
    {
      townname: '茄萣區',
    },
    {
      townname: '永安區',
    },
    {
      townname: '彌陀區',
    },
    {
      townname: '梓官區',
    },
    {
      townname: '旗山區',
    },
    {
      townname: '美濃區',
    },
    {
      townname: '六龜區',
    },
    {
      townname: '甲仙區',
    },
    {
      townname: '杉林區',
    },
    {
      townname: '內門區',
    },
    {
      townname: '茂林區',
    },
    {
      townname: '桃源區',
    },
    {
      townname: '那瑪夏區',
    },
  ],
  新北市: [
    {
      townname: '新莊區',
    },
    {
      townname: '林口區',
    },
    {
      townname: '五股區',
    },
    {
      townname: '蘆洲區',
    },
    {
      townname: '三重區',
    },
    {
      townname: '泰山區',
    },
    {
      townname: '新店區',
    },
    {
      townname: '石碇區',
    },
    {
      townname: '深坑區',
    },
    {
      townname: '坪林區',
    },
    {
      townname: '烏來區',
    },
    {
      townname: '板橋區',
    },
    {
      townname: '三峽區',
    },
    {
      townname: '鶯歌區',
    },
    {
      townname: '樹林區',
    },
    {
      townname: '中和區',
    },
    {
      townname: '土城區',
    },
    {
      townname: '瑞芳區',
    },
    {
      townname: '平溪區',
    },
    {
      townname: '雙溪區',
    },
    {
      townname: '貢寮區',
    },
    {
      townname: '金山區',
    },
    {
      townname: '萬里區',
    },
    {
      townname: '淡水區',
    },
    {
      townname: '汐止區',
    },
    {
      townname: '三芝區',
    },
    {
      townname: '石門區',
    },
    {
      townname: '八里區',
    },
    {
      townname: '永和區',
    },
  ],
  宜蘭縣: [
    {
      townname: '宜蘭市',
    },
    {
      townname: '頭城鎮',
    },
    {
      townname: '礁溪鄉',
    },
    {
      townname: '壯圍鄉',
    },
    {
      townname: '員山鄉',
    },
    {
      townname: '羅東鎮',
    },
    {
      townname: '五結鄉',
    },
    {
      townname: '冬山鄉',
    },
    {
      townname: '蘇澳鎮',
    },
    {
      townname: '三星鄉',
    },
    {
      townname: '大同鄉',
    },
    {
      townname: '南澳鄉',
    },
  ],
  桃園市: [
    {
      townname: '桃園區',
    },
    {
      townname: '大溪區',
    },
    {
      townname: '中壢區',
    },
    {
      townname: '楊梅區',
    },
    {
      townname: '蘆竹區',
    },
    {
      townname: '大園區',
    },
    {
      townname: '龜山區',
    },
    {
      townname: '八德區',
    },
    {
      townname: '龍潭區',
    },
    {
      townname: '平鎮區',
    },
    {
      townname: '新屋區',
    },
    {
      townname: '觀音區',
    },
    {
      townname: '復興區',
    },
  ],
  嘉義市: [
    {
      townname: '嘉義市',
    },
  ],
  新竹縣: [
    {
      townname: '竹東鎮',
    },
    {
      townname: '關西鎮',
    },
    {
      townname: '新埔鎮',
    },
    {
      townname: '竹北市',
    },
    {
      townname: '湖口鄉',
    },
    {
      townname: '橫山鄉',
    },
    {
      townname: '新豐鄉',
    },
    {
      townname: '芎林鄉',
    },
    {
      townname: '寶山鄉',
    },
    {
      townname: '北埔鄉',
    },
    {
      townname: '峨眉鄉',
    },
    {
      townname: '尖石鄉',
    },
    {
      townname: '五峰鄉',
    },
  ],
  苗栗縣: [
    {
      townname: '苗栗市',
    },
    {
      townname: '苑裡鎮',
    },
    {
      townname: '通霄鎮',
    },
    {
      townname: '公館鄉',
    },
    {
      townname: '銅鑼鄉',
    },
    {
      townname: '三義鄉',
    },
    {
      townname: '西湖鄉',
    },
    {
      townname: '頭屋鄉',
    },
    {
      townname: '竹南鎮',
    },
    {
      townname: '頭份市',
    },
    {
      townname: '造橋鄉',
    },
    {
      townname: '後龍鎮',
    },
    {
      townname: '三灣鄉',
    },
    {
      townname: '南庄鄉',
    },
    {
      townname: '大湖鄉',
    },
    {
      townname: '卓蘭鎮',
    },
    {
      townname: '獅潭鄉',
    },
    {
      townname: '泰安鄉',
    },
  ],
  南投縣: [
    {townname: '南投市'},
    {
      townname: '埔里鎮',
    },
    {
      townname: '草屯鎮',
    },
    {
      townname: '竹山鎮',
    },
    {
      townname: '集集鎮',
    },
    {
      townname: '名間鄉',
    },
    {
      townname: '鹿谷鄉',
    },
    {
      townname: '中寮鄉',
    },
    {
      townname: '魚池鄉',
    },
    {
      townname: '國姓鄉',
    },
    {
      townname: '水里鄉',
    },
    {
      townname: '信義鄉',
    },
    {
      townname: '仁愛鄉',
    },
  ],
  彰化縣: [
    {
      townname: '彰化市',
    },
    {
      townname: '鹿港鎮',
    },
    {
      townname: '和美鎮',
    },
    {
      townname: '北斗鎮',
    },
    {
      townname: '員林市',
    },
    {
      townname: '溪湖鎮',
    },
    {
      townname: '田中鎮',
    },
    {
      townname: '二林鎮',
    },
    {
      townname: '線西鄉',
    },
    {
      townname: '伸港鄉',
    },
    {
      townname: '福興鄉',
    },
    {
      townname: '秀水鄉',
    },
    {
      townname: '花壇鄉',
    },
    {
      townname: '芬園鄉',
    },
    {
      townname: '大村鄉',
    },
    {
      townname: '埔鹽鄉',
    },
    {
      townname: '埔心鄉',
    },
    {
      townname: '永靖鄉',
    },
    {
      townname: '社頭鄉',
    },
    {
      townname: '二水鄉',
    },
    {
      townname: '田尾鄉',
    },
    {
      townname: '埤頭鄉',
    },
    {
      townname: '芳苑鄉',
    },
    {
      townname: '大城鄉',
    },
    {
      townname: '竹塘鄉',
    },
    {
      townname: '溪州鄉',
    },
  ],
  新竹市: [
    {
      townname: '新竹市',
    },
  ],
  雲林縣: [
    {
      townname: '斗六市',
    },
    {
      townname: '斗南鎮',
    },
    {
      townname: '虎尾鎮',
    },
    {
      townname: '西螺鎮',
    },
    {
      townname: '土庫鎮',
    },
    {
      townname: '北港鎮',
    },
    {
      townname: '古坑鄉',
    },
    {
      townname: '大埤鄉',
    },
    {
      townname: '莿桐鄉',
    },
    {
      townname: '林內鄉',
    },
    {
      townname: '二崙鄉',
    },
    {
      townname: '崙背鄉',
    },
    {
      townname: '麥寮鄉',
    },
    {
      townname: '東勢鄉',
    },
    {
      townname: '褒忠鄉',
    },
    {
      townname: '臺西鄉',
    },
    {
      townname: '元長鄉',
    },
    {
      townname: '四湖鄉',
    },
    {
      townname: '口湖鄉',
    },
    {
      townname: '水林鄉',
    },
  ],
  嘉義縣: [
    {
      townname: '朴子市',
    },
    {
      townname: '布袋鎮',
    },
    {
      townname: '大林鎮',
    },
    {
      townname: '民雄鄉',
    },
    {
      townname: '溪口鄉',
    },
    {
      townname: '新港鄉',
    },
    {
      townname: '六腳鄉',
    },
    {
      townname: '東石鄉',
    },
    {
      townname: '義竹鄉',
    },
    {
      townname: '鹿草鄉',
    },
    {
      townname: '太保市',
    },
    {
      townname: '水上鄉',
    },
    {
      townname: '中埔鄉',
    },
    {
      townname: '竹崎鄉',
    },
    {
      townname: '梅山鄉',
    },
    {
      townname: '番路鄉',
    },
    {
      townname: '大埔鄉',
    },
    {
      townname: '阿里山鄉',
    },
  ],
  屏東縣: [
    {
      townname: '屏東市',
    },
    {
      townname: '潮州鎮',
    },
    {
      townname: '東港鎮',
    },
    {
      townname: '恆春鎮',
    },
    {
      townname: '萬丹鄉',
    },
    {
      townname: '長治鄉',
    },
    {
      townname: '麟洛鄉',
    },
    {
      townname: '九如鄉',
    },
    {
      townname: '里港鄉',
    },
    {
      townname: '鹽埔鄉',
    },
    {
      townname: '高樹鄉',
    },
    {
      townname: '萬巒鄉',
    },
    {
      townname: '內埔鄉',
    },
    {
      townname: '竹田鄉',
    },
    {
      townname: '新埤鄉',
    },
    {
      townname: '枋寮鄉',
    },
    {
      townname: '新園鄉',
    },
    {
      townname: '崁頂鄉',
    },
    {
      townname: '林邊鄉',
    },
    {
      townname: '南州鄉',
    },
    {
      townname: '佳冬鄉',
    },
    {
      townname: '琉球鄉',
    },
    {
      townname: '車城鄉',
    },
    {
      townname: '滿州鄉',
    },
    {
      townname: '枋山鄉',
    },
    {
      townname: '三地門鄉',
    },
    {
      townname: '霧臺鄉',
    },
    {
      townname: '瑪家鄉',
    },
    {
      townname: '泰武鄉',
    },
    {
      townname: '來義鄉',
    },
    {
      townname: '春日鄉',
    },
    {
      townname: '獅子鄉',
    },
    {
      townname: '牡丹鄉',
    },
  ],
  花蓮縣: [
    {
      townname: '花蓮市',
    },
    {
      townname: '光復鄉',
    },
    {
      townname: '玉里鎮',
    },
    {
      townname: '新城鄉',
    },
    {
      townname: '吉安鄉',
    },
    {
      townname: '壽豐鄉',
    },
    {
      townname: '鳳林鎮',
    },
    {
      townname: '豐濱鄉',
    },
    {
      townname: '瑞穗鄉',
    },
    {
      townname: '富里鄉',
    },
    {
      townname: '秀林鄉',
    },
    {
      townname: '萬榮鄉',
    },
    {
      townname: '卓溪鄉',
    },
  ],
  臺東縣: [
    {
      townname: '臺東市',
    },
    {
      townname: '成功鎮',
    },
    {
      townname: '關山鎮',
    },
    {
      townname: '卑南鄉',
    },
    {
      townname: '大武鄉',
    },
    {
      townname: '太麻里鄉',
    },
    {
      townname: '東河鄉',
    },
    {
      townname: '長濱鄉',
    },
    {
      townname: '鹿野鄉',
    },
    {
      townname: '池上鄉',
    },
    {
      townname: '綠島鄉',
    },
    {
      townname: '延平鄉',
    },
    {
      townname: '海端鄉',
    },
    {
      townname: '達仁鄉',
    },
    {
      townname: '金峰鄉',
    },
    {
      townname: '蘭嶼鄉',
    },
  ],
  金門縣: [
    {
      townname: '金湖鎮',
    },
    {
      townname: '金沙鎮',
    },
    {
      townname: '金城鎮',
    },
    {
      townname: '金寧鄉',
    },
    {
      townname: '烈嶼鄉',
    },
    {
      townname: '烏坵鄉',
    },
  ],
  澎湖縣: [
    {
      townname: '馬公市',
    },
    {
      townname: '湖西鄉',
    },
    {
      townname: '白沙鄉',
    },
    {
      townname: '西嶼鄉',
    },
    {
      townname: '望安鄉',
    },
    {
      townname: '七美鄉',
    },
  ],
  連江縣: [
    {
      townname: '南竿鄉',
    },
    {
      townname: '北竿鄉',
    },
    {
      townname: '莒光鄉',
    },
    {
      townname: '東引鄉',
    },
  ],
};
